

























































































































import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseInputField from '@/components/base/BaseInputField.vue';
import BaseRow from '@/components/base/BaseRow.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import BaseSideLabel from '@/components/base/BaseSideLabel.vue';
import LayoutHeader from '@/layouts/LayoutHeader.vue';
import LayoutModalEdit from '@/layouts/LayoutModalEdit.vue';
import Vue, { VueConstructor } from 'vue';
import { endOfDay } from 'date-fns';
import { MAccountNumber } from '@/models/MAccountNumber';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinModes } from '@/components/mixins/modes';
import { mixinSync } from '@/components/mixins/sync';
import { MOutgoing } from '@/models/MOutgoing';
import { MTax } from '@/models/MTax';
import { slipRangeList } from '@/utils/outgoing';
import { utIsEmpty } from '@/utils/empty';

export default (Vue as VueConstructor).extend({
  name: 'OutgoingEdit',

  components: {
    BaseDatePicker,
    BaseInputField,
    BaseRow,
    BaseSelect,
    BaseSideLabel,
    LayoutModalEdit,
    LayoutHeader,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinModes,
    mixinSync,
  ],

  props: {
    ID: {
      type: String,
      required: true,
    },

    year: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      slipRangeList,
    };
  },

  computed: {
    accountNumberList() {
      return MAccountNumber.all().map(
        accountNumber => accountNumber.asJson,
      );
    },

    currentOutgoing: {
      get(): MOutgoing {
        return MOutgoing.find(this.ID);
      },

      async set(outgoing: MOutgoing) {
        this.dirty = true;

        await MOutgoing.update({
          where: this.ID,

          data: outgoing,
        });
      },
    },

    currentOutgoingAccountNumber() {
      if (utIsEmpty(this.currentOutgoing.AccountNumberID)) {
        return {};
      }

      return MAccountNumber.find(this.currentOutgoing.AccountNumberID).asJson;
    },

    currentOutgoingBankCashNumber() {
      if (utIsEmpty(this.currentOutgoing.BankCashID)) {
        return {};
      }

      return MAccountNumber.find(this.currentOutgoing.BankCashID).asJson;
    },

    currentOutgoingDate() {
      return this.currentOutgoing.Date;
    },

    currentOutgoingTax() {
      if (utIsEmpty(this.currentOutgoing.AccountNumberID)) {
        if (utIsEmpty(this.currentOutgoing.Tax)) {
          return {};
        }

        return MTax.query().where('Percent', String(this.currentOutgoing.Tax)).first()?.asListData;
      } else {
        if (utIsEmpty(this.currentOutgoing.Tax)) {
          const tax = MAccountNumber.find(this.currentOutgoing.AccountNumberID).Tax;

          this.updateOutgoing('Tax', tax);

          return MTax.query().where('Percent', String(tax)).first()?.asListData;
        } else {
          return MTax.query().where('Percent', String(this.currentOutgoing.Tax)).first()?.asListData;
        }
      }
    },

    currentSlipNumber() {
      if (utIsEmpty(this.currentOutgoing.SlipRange)) {
        return this.currentOutgoing.SlipNumber ? this.currentOutgoing.SlipNumber : 1;
      } else if (utIsEmpty(this.currentOutgoing.SlipNumber)) {
        const slipNumber = MOutgoing
          .query()
          .where('SlipRange', this.currentOutgoing.SlipRange)
          .where(outgoing => {
            return outgoing.Date.substr(0, 4) === this.year;
          })
          .get().length + 1;

        this.updateOutgoing('SlipNumber', ((slipNumber === 0) ? 1 : slipNumber));
      }

      return this.currentOutgoing.SlipNumber;
    },

    currentSlipRange() {
      if (utIsEmpty(this.currentOutgoing.SlipRange)) {
        return {};
      }

      return {
        Id: this.currentOutgoing.SlipRange,
        Name: this.$tc(`outgoing.slipRange.${this.currentOutgoing.SlipRange}`),
      };
    },

    taxList() {
      return MTax.all().map(
        tax => tax.asListData,
      );
    },
  },

  methods: {
    onLiveDateChange(data): void {
      this.currentOutgoing = {
        ...this.currentOutgoing,
        Date: endOfDay(new Date(data)).toISOString(),
      };
    },

    closeModal(): void {
      this.$router.push(this.routeBack);
    },

    async remove(): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('outgoing.alert.delete', {
          name: this.currentOutgoing.Name,
        }),
      );

      if (confirmation.value) {
        await MOutgoing._delete(this.ID);

        this.closeModal();
      }
    },

    sync(): void {
      MOutgoing._update(this.currentOutgoing);
    },

    updateOutgoing(Prop, Value): void {
      if (this.currentOutgoing[Prop] !== Value) {
        this.currentOutgoing = {
          ...this.currentOutgoing,
          [`${Prop}`]: Value,
        };
      }
    },
  },
});
