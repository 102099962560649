















































import BaseButtonText from '@/components/base/button/BaseButtonText.vue';
import BaseGridTable from '@/components/base/grid/BaseGridTable.vue';
import BaseSelect from '@/components/base/BaseSelect.vue';
import LayoutPage from '@/layouts/LayoutPage.vue';
import Vue, { VueConstructor } from 'vue';
import { mixinAlert } from '@/components/mixins/alert';
import { mixinModal } from '@/components/mixins/modal';
import { mixinPDF } from '@/components/mixins/pdf';
import { MOutgoing } from '@/models/MOutgoing';
import { outgoingGridActionList } from '@/utils/gridActions/outgoingGridActionList';
import {
  outgoingsRouteEdit,
  outgoingsRouteNew,
  outgoingsRouteShow,
} from '@/views/accounting/outgoings/routes';
import { sortAlphaNum } from '@/utils/filters/sortAlphaNumeric';

export default (Vue as VueConstructor<Vue>).extend({
  name: 'OutgoingShow',

  components: {
    BaseButtonText,
    BaseGridTable,
    BaseSelect,
    LayoutPage,
  },

  mixins: [
    mixinAlert,
    mixinModal,
    mixinPDF,
  ],

  data() {
    return {
      currentYear: String(new Date().getFullYear()),
      gridActionList: outgoingGridActionList,
      currentOutgoingList: [],

      gridColumnDefinition: [
        {
          headerName: this.$tc('outgoing.property.slipRange'),
          field: 'slip',
          sortable: true,
          comparator: (a, b) => {
            return sortAlphaNum(a, b);
          },
        },
        {
          headerName: this.$tc('outgoing.property.invoiceNumber'),
          field: 'invoiceNumber',
        },
        {
          headerName: this.$tc('outgoing.property.description'),
          field: 'description',
        },
        {
          headerName: this.$tc('outgoing.property.date'),
          field: 'date',
        },
        {
          headerName: this.$tc('outgoing.property.grossAmount'),
          field: 'grossAmount',
        },
        {
          headerName: this.$tc('outgoing.property.tax'),
          field: 'tax',
        },
        {
          headerName: '',
          field: 'taxNumber',
        },
        {
          headerName: this.$tc('outgoing.property.account'),
          field: 'account',
        },
        {
          headerName: this.$tc('outgoing.property.bank'),
          field: 'bank',
        },
      ],
      yearList: [],
    };
  },

  computed: {
    displayedYear(): Object {
      return {
        year: this.currentYear,
      };
    },

    outgoingList(): Array<MOutgoing> {
      return MOutgoing
        .query()
        .where(outgoing => {
          return outgoing.Date.substr(0, 4) === this.currentYear;
        }).get();
    },

    gridRowData(): Array<any> {
      return this.outgoingList.map(
        outgoing => outgoing.asJson,
      );
    },
  },

  methods: {
    add(): void {
      this.$router.push({
        name: outgoingsRouteNew(this.$i18n.locale).name,

        params: {
          year: this.currentYear,
        },
      });
    },

    closeModal(): void {
      this.$router.push(outgoingsRouteShow(this.$i18n.locale));
    },

    edit(ID: string): void {
      this.$router.push({
        name: outgoingsRouteEdit(this.$i18n.locale).name,

        params: {
          ID: ID,
          year: this.currentYear,
        },
      });
    },

    exportPdf(): void {
      this.exportOutgoingData(this.gridRowData, this.gridColumnDefinition,
        `${this.$tc('outgoing.model', 2)}-${this.currentYear}`);
    },

    loadYear(year: any): void {
      this.currentYear = String(year.year);
    },

    async remove(ID: string): Promise<any> {
      const confirmation = await this.confirmAlertDelete(
        this.$tc('outgoing.alert.delete', {
          name: MOutgoing.find(ID).Description,
        }),
      );

      if (confirmation.value) {
        MOutgoing._delete(ID);
      }
    },
  },

  async beforeMount(): Promise<any> {
    for (let i = 0; i < 8; i++) {
      this.yearList.push(
        {
          year: String((Number(this.currentYear) - i)),
        },
      );
    }
  },
});
